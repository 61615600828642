import './styles/generic.scss';

import 'bootstrap/js/dist/base-component';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/carousel';

// import './scripts/components/theme-mode-switch';
import './scripts/components/sticky-navbar';
import './scripts/components/animation-on-scroll';
// import './scripts/components/smooth-scroll';
// import './scripts/components/scroll-top-button';
// import './scripts/components/masonry-grid';
// import './scripts/components/password-visibility-toggle';
// import './scripts/components/interactive-map';
// import './scripts/components/parallax';
import './scripts/components/carousel';
// import './scripts/components/gallery';
// import './scripts/components/charts';
// import './scripts/components/range-slider';
// import './scripts/components/date-picker';
// import './scripts/components/calendar';
// import './scripts/components/form-validation';
// import './scripts/components/input-formatter';
// import './scripts/components/binded-label';
// import './scripts/components/binded-content';
// import './scripts/components/count-input';
// import './scripts/components/input-autofocus';
// import './scripts/components/tooltip';
// import './scripts/components/popover';
// import './scripts/components/toast';
// import './scripts/components/video-button';
// import './scripts/components/price-switch';
// import './scripts/components/checkbox-toggle';
// import './scripts/components/countdown';
// import './scripts/components/subscription-form';

console.info('soulbuddys-homeforanimals.de')

export function updateAnimalListUrl(data: { animalList: any }&{[key: string]: string[]|string}): void {
    delete data.animalList;
    
    console.info(data);

    const url = new URL(window.location.href);

    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key) && data[key] != null && data[key].length != 0) {
            const value = data[key];
            url.searchParams.set(key, typeof value == 'string' ? value : value.join(','));
        }
    }

    history.pushState(data, null, url.toString().replace(new RegExp('%2C', 'g'), ','));
}